import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { AiFillFire } from "react-icons/ai";
import { GiIceCube } from "react-icons/gi";
import { FaCompressArrowsAlt } from "react-icons/fa";

function App() {
  const [post, setPost] = useState();

  useEffect(() => {
    axios
      .get(
        "https://labview-iot-d6215-default-rtdb.asia-southeast1.firebasedatabase.app/data2.json"
      )
      .then((response) => {
        setPost(response.data);
      });
  }, [post]);
  return (
    <>
      <div style={{ width: 200, height: 200 }}>
        <CircularProgressbarWithChildren
          value={post?.sens_1}
          circleRatio={0.75}
          maxValue={25}
          styles={buildStyles({
            rotation: 1 / 2 + 1 / 8,
            trailColor: "#eee",
          })}
        >
          {post?.sens_1 > 20 ? (
            <AiFillFire size={"2rem"} color="#fbb741" />
          ) : (
            <GiIceCube size={"2rem"} color="#368BC1" />
          )}

          <br />
          <div style={{ fontSize: 20, marginTop: -5 }}>
            <strong>{post?.sens_1.toFixed(2)} °C</strong>
          </div>
        </CircularProgressbarWithChildren>
        <br />
        <br />
        <CircularProgressbarWithChildren
          value={post?.sens_2}
          circleRatio={0.75}
          maxValue={50}
          styles={buildStyles({
            rotation: 1 / 2 + 1 / 8,
            trailColor: "#eee",
          })}
        >
          <FaCompressArrowsAlt size={"2rem"} color="#ff8a8a" />
          <br />
          <div style={{ fontSize: 20, marginTop: -5 }}>
            <strong>{post?.sens_2.toFixed(2)} psig</strong>
          </div>
        </CircularProgressbarWithChildren>
      </div>
    </>
  );
}

export default App;
